<template>
  <a-modal 
    title="掌柜人员" 
    :width="modalWidth" 
    :visible="visible" 
    :confirmLoading="loading" 
    @ok="handleOk" 
    @cancel="cancel">
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="掌柜">
          <a-select
            allow-clear
            show-search
            :value="value"
            placeholder="请选择掌柜人员"
            style="width: 200px"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            @search="findByRole"
            @change="handleChange"
            @focus="findAll"
            v-decorator="['developer', { rules: [{ required: true, message: '请选择掌柜人员' }] }]"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in data" :key="d.id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { findByRole } from '@/api/users'
import { housesManagers } from '@/api/house'

// 表单字段
const fields = ['name']
// console.log(this.loading, 'loading');
export default {
  props: {
    houseId: {
      type: String,
      default: () => ''
    },
    model: {
      type: Object,
      default: () => null
    },
    selectedManage: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      residentLink: '',
      // search
      data: [],
      currentValue: '',
      value: undefined,
      fetching: false,
      loading: false
    }
  },
  computed: {
    serial() {
      return this.$store.state.env.serial
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    cancel() {
      this.visible = false
      // 重置表单数据
      this.form.resetFields()
    },
    findByRole(value, callback) {
      this.fetching = true
    },
    findAll(value) {
      findByRole('manager').then(d => {
        this.data = d.data.filter(item => {
          return this.selectedManage.indexOf(item.id) === -1
        })
      })
    },
    handleOk() {
      const form = this.form
      this.loading = true
      form.validateFields((errors, values) => {
        console.log(values)
        if (!errors) {
          const requestParameters = {
            houseId: this.houseId,
            method: 'patch',
            data: values.developer
          }
          // 新增
          housesManagers(requestParameters)
            .then(res => {
              setTimeout(() => {
                this.visible = false
                this.loading = false
                // 重置表单数据
                form.resetFields()
                // 刷新表格
                const msg = '添加成功'
                this.$message.success(msg)
                this.$emit('reload')
              }, 1000)
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          // this.confirmLoading = false
        }
      })
    },
    // search
    handleChange(value) {
      console.log('residentLink', value)
      this.residentLink = value
    },
    filterInclude(all, current) {
      const currentArr = current.map(item => {
        return item.id
      })
      const allArr = all.map(item => {
        return item.id
      })
      const newArr = []
      allArr.filter((item, index) => {
        if (!currentArr.includes(item)) {
          console.log(item, 'item')
          newArr.push(all[index])
        }
      })
      console.log(newArr, 'newArr')
      return newArr
    }
  },
  created() {
    console.log('selectedManage', this.selectedManage);
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
