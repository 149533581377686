<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="掌柜电话">
              <a-input v-model="managerPhone" allow-clear placeholder="请输入掌柜电话" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <span>
              <a-button type="primary" @click="showManage">提交</a-button>
              <span style="margin-left: 15px;">经纪人只能看到掌柜电话</span>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-modal
        title="Title"
        :visible="manageVisible"
        :confirm-loading="managerLoading"
        @ok="chooseManage"
        @cancel="hideManage"
      >
        <p>{{ managerPhone ? `设置 ${managerPhone} 为掌柜电话？` : '请先输入掌柜电话' }}</p>
      </a-modal>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete">移除</a-button>
    </div>

    <a-modal
      title="刪除"
      :visible="del.visible"
      :confirm-loading="del.confirmLoading"
      @ok="handleDeleteOk"
      @cancel="handleDeleteCancel"
    >
      <p>{{ del.ModalText }}</p>
    </a-modal>

    <s-table
      ref="table"
      size="default"
      :rowKey="record => record.id"
      :columns="columns"
      :data="residentsData"
      :alert="true"
      :rowSelection="rowSelection"
      showPagination="auto"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="status" slot-scope="text">
        <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
      </span>
      <span slot-scope="text">
        <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
      </span>

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
        </template>
      </span>
    </s-table>
    <create-form ref="createModal" :houseId="houseId" @reload="reload" :selectedManage="selectedManage" />
  </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { deleteHousesManager, saveHouse, findByIdHouse, findManagerByHouse } from '@/api/house'
import StepByStepModal from './modules/StepByStepModal'
import CreateForm from './modules/CreateManage'

const columns = [
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '手机号',
    dataIndex: 'phone'
  }
  // {
  //   title: '所属部门',
  //   dataIndex: 'department'
  // }
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   width: '150px',
  //   scopedSlots: { customRender: 'action' }
  // }
]

const statusMap = {
  0: {
    status: 'default',
    text: '关闭'
  },
  1: {
    status: 'processing',
    text: '运行中'
  },
  2: {
    status: 'success',
    text: '已上线'
  },
  3: {
    status: 'error',
    text: '异常'
  }
}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    StepByStepModal
  },
  data() {
    this.columns = columns
    return {
      houseId: '',
      houseLink: '',
      residentHref: null,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      currentResidents: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 批量删除 modal
      del: {
        ModalText: '您要移除这些项目吗',
        visible: false,
        confirmLoading: false
      },
      // 掌柜电话
      manageVisible: false,
      managerLoading: false,
      managerPhone: '',
      // 加载数据方法 必须为 Promise 对象
      residentsData: parameter => {
        console.log(parameter, 'parameter')
        let sorterStr = ''
        if (parameter.sortOrder === 'descend') {
          sorterStr = `${parameter.sortField},desc`
        } else if (parameter.sortOrder === 'ascend') {
          sorterStr = `${parameter.sortField}`
        } else {
          sorterStr = `createTime,desc`
        }
        const requestParameters = {
          houseId: this.houseId,
          page: parameter.pageNo - 1,
          size: parameter.pageSize,
          sort: sorterStr
        }
        return findManagerByHouse(requestParameters).then(res => {
          if (res) {
            const result = this.resFormat(res)
            this.selectedManage = result.data.map(item => {
              return item.id
            })
            return result
          }
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      selectedManage: []
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  mounted() {
    const { houseId } = this
    findByIdHouse(houseId).then(res => {
      console.log(res, 'res phone')
      this.managerPhone = res.managerPhone
    })
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    handleAdd() {
      this.$refs.createModal.showModal()
    },
    // 批量删除
    handleDelete() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.del.visible = true
      }
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除这些项目吗'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          this.selectedRows.forEach((item, index) => {
            console.log(item, 'item')
            const requestParameters = {
              houseId: this.houseId,
              managerId: item.id
            }
            deleteHousesManager(requestParameters)
              .then(res => {
                // 刷新表格
                this.$refs.table.refresh()
                this.$message.success('删除成功', 2)
                this.del.visible = false
                this.confirmLoading = false
                this.selectedRows.splice(0, this.selectedRows.length)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          })
        } else {
          this.del.visible = false
          this.confirmLoading = false
        }
      }, 2000)
    },
    handleDeleteCancel(e) {
      this.del.visible = false
    },
    handleEdit(record) {
      this.visible = true
      this.mdl = { ...record }
    },
    handleCancel() {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    showManage() {
      const { managerPhone } = this
      const reg = /^1([0-9][0-9]|4[57]|5[0-35-9]|7[0678]|8[0-9])\d{8}$/
      if (!reg.test(managerPhone)) {
        this.$message.error('请输入正确手机号')
      } else {
        this.manageVisible = true
      }
    },
    hideManage() {
      this.manageVisible = false
    },
    chooseManage() {
      this.managerLoading = true
      const { managerPhone, houseId } = this
      const params = {
        id: houseId,
        managerPhone
      }
      saveHouse(params)
        .then(res => {
          this.managerLoading = false
          this.manageVisible = false
          this.$message.success('设置成功')
        })
        .catch(() => {
          this.managerLoading = false
        })
    },
    getResident(data) {
      console.log(data, 'data')
      this.residentHref = data
    }
  }
}
</script>
